<template>
    <div class="UsersList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="userForm" ref="userForm">
                    <el-form-item label="用户账号" prop="account">
                        <el-input placeholder="用户账号" v-model="userForm.account"></el-input>
                    </el-form-item>
                    <el-form-item label="角色名称" prop="roleId">
                        <el-select placeholder="请选择角色名称" v-model="userForm.roleId">
                            <el-option v-for="(role, index) in roleList" :key="index" :label="role.roleName"
                                :value="role.roleId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button class="btn" @click="$router.push({name:'user-create'})" icon="el-icon-circle-plus-outline">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="userList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="id" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="account" label="账号" align="center"> </el-table-column>
                <el-table-column prop="realName" label="姓名" align="center">
                </el-table-column>
                <el-table-column prop="roleName" label="角色名称" align="center">
                </el-table-column>
                <el-table-column prop="mobile" label="联系方式" align="center">
                </el-table-column>
                <el-table-column prop="regDate" label="注册时间" align="center" width="160">
                </el-table-column>
                <el-table-column prop="logoutDate" label="登出时间" align="center" width="160">
                </el-table-column>
                <el-table-column prop="userStatus_text" label="用户状态" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改"
                            @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini"  title="重置密码"
                            @click="resetPass(scope.row.account)">重置密码</el-button>
                        <el-button type="text" size="mini" title="删除"
                            @click="del(scope.row.account)" class="delColor">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="userForm.pageNum" :page-sizes="[ 5, 10, 20, 50, 100]" :page-size="userForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>

<script>
import { searchUserData, delUser, resetUserPass } from '@/api/user'
import { queryRoleAll } from '@/api/role'
export default {
    data() {
        return {
            userList: [],
            roleList: [],
            userForm: {
                account: '',
                roleId: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
        }
    },
    mounted() {
        this.loadSelect()
        this.searchUserData()
    },
    methods: {
        handleSizeChange(val) {
            this.userForm.pageSize = val;
            this.userForm.pageNum = 1;
            this.searchUserData();
        },
        handleCurrentChange(val) {
            this.userForm.pageNum = val;
            this.searchUserData();
        },
        async loadSelect() {
            await queryRoleAll().then(res => {
                if (res.code === '000000') {
                    this.roleList = res.data
                }
            })
        },
        //查询
        searchUserData() {
            searchUserData(this.userForm).then(res => {
                if (res.code === '000000') {
                    this.userList = res.data.list
                    this.total = res.data.total
                    var count = 1;
                    this.userList.forEach((item) => {
                        item.id = count++
                        item.userStatus === 1 ? (item.userStatus_text = '启用') : (item.userStatus_text = '停用')
                        // count= count +1;
                    })
                }

            })

        },
        handleEdit({ userId }) {
            this.$router.push({
                name: 'user-edit',
                params: {
                    userId
                }
            })
        },
        //重置密码
        resetPass(account) {
            this.$confirm('是否重置' + account + '用户的密码？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                resetUserPass(account, null).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('重置' + account + '用户密码成功')
                        this.searchUserData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消重置' + account + '用户密码'
                })
            })

        },

        //删除
        del(account) {
            this.$confirm('是否删除账号为“'+account+'”的用户？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delUser(account).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        this.searchUserData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },

        //搜索
        handleSearch() {
            this.searchUserData(this.userForm);
        },
        //重置
        handleReset() {
            this.userForm = {};
            this.searchUserData(this.userForm);
        }
    }
}

</script>

<style lang="scss" scoped>
.UsersList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }
    .delColor {
        color: red;
    }

}
</style>